export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Fiddlebops",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://data.gameflare.com/games/10728/5mzWFwWV70hefB/index.html",
    domain: "fiddlebops.com",
    gaId: "G-HPNSQTQG39",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
